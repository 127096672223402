import React from "react";
import PropTypes from "prop-types";
import { Row } from "react-bootstrap";
import { Link } from "components/common"


function PageHeader(props) {
    return (
        <div className="layout-page-header">
            <Row>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        {props.breadcrumbs.map( (crumb, index) => {
                            return (
                                <li key={index} className="breadcrumb-item">
                                    <Link to={crumb.to} classsName="breadcrumb-link">{crumb.caption}</Link>
                                </li>
                            )
                        })};
                    </ol>
                </nav>
            </Row>
            <Row>
                <div className="page-title">{props.title}</div>
            </Row>
            <hr />
        </div>
    );
}
export default PageHeader;
PageHeader.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    breadcrumbs: PropTypes.array,
};
PageHeader.defaultProps = {
    title: "Page Title Here",
    description: null,
    breadcrumbs: null,
};
