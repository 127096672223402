import * as default_theme from './theme-definitions/theme-default';
import * as dark_theme from './theme-definitions/theme-dark';
import * as light_theme from './theme-definitions/theme-light';
import * as yellow_theme from './theme-definitions/theme-yellow';

export const UI_THEMES={
    default:    { name:"default",   def : default_theme         },
    dark:       { name:"dark",      def : dark_theme            },
    light:      { name:"light",     def : light_theme            },
    yellow:     { name:"yellow",    def : yellow_theme          },
}; 

export const get_default_theme_definition = () => {return {...default_theme}}