import React from "react";
import PropTypes from "prop-types";
import { Link as RRDomLink } from "react-router-dom";

const Link = (props) => {
    if (props.href !== undefined && props.href !== null) {
        return (
            <RRDomLink href={props.href} onClick={props.onClick}>
                {props.children}
            </RRDomLink>
        );
    } else if (props.to !== undefined && props.to !== null) {
        return (
            <RRDomLink to={props.to} onClick={props.onClick}>
                {props.children}
            </RRDomLink>
        );
    } else {
        return <div className="text-danger fw-bold">LINK DEV ERROR!</div>;
    }
};
Link.propTypes = {
    to: PropTypes.string,
    href: PropTypes.string,
    onClick: PropTypes.func,
};
Link.defaultProps = {
    to: null,
    text: null,
    href: null,
    onClick: null,
};
export default Link;
