import React from 'react';
import { getAllowedRoutes } from "config/PrivateRoutes.config";
import MapAllowedRoutes from 'routing/MapAllowedRoutes';
import { APP_CONSTANTS } from 'config';

function PrivateRoutes() {
	//console.log(match);
	let allowedRoutes = [];
	allowedRoutes = getAllowedRoutes();
	return (		
		<MapAllowedRoutes routes={allowedRoutes} basePath={ APP_CONSTANTS.AUTH_BASE_PATH } isAddNotFound />
	);
}
export default PrivateRoutes;