import { createContext, useContext, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    reset_all,
    reset_menus,

    set_theme,

    main_menu_reset, 
    main_menu_show, 
    main_menu_hide,
    main_menu_toggle,

    user_menu_reset,
    user_menu_show, 
    user_menu_hide,
    user_menu_toggle,

    toast_show,
    toast_dismissAll,

} from "redux/slices/ui-redux-slice";

import { ToastContainer, ToastTypes} from "components/common/lib/messaging/Toast";
import { Icon, ScrollUp } from "components/common";

import { UI_THEMES } from 'config/theme';   // for use for easy export, to refernce any of the system themes. 

const UIContext = createContext();

export const UiProvider = ({ children }) => {
    const dispatch = useDispatch();

    // GET STATE VALUES FOR UI, we'll export below. 
     
    //-- Menu States
    const {
        main_menu : { visible:main_menu_visible } = { main_menu_visible:false },       /// deep destructuring of object, renaming variables, and setting default values to the variables. 
        user_menu : { visible:user_menu_visible } = { user_menu_visible:false },        
    } = useSelector((state) => state.ui);

    //-- Theme State, for export bloew
    const { 
       theme : {
            name: theme_name,
            def: theme_definition,
            default: theme_default,
       } = {
            theme_name:"",
            theme_definition:{},
            theme_default:""
       }
    } = useSelector((state) => state.ui);


   // const 

    /************************************************************************************************************************************************************* */
    /************************************************************************************************************************************************************* */


    const value = useMemo(
        () => ({
            ui_reset_all: async () => { return dispatch(reset_all()) },
            ui_reset_menus: async () => { return dispatch(reset_menus()) },

            main_menu : {
                visible: main_menu_visible,
                reset: ( async () => { return dispatch( main_menu_reset() ) }),
                show:  ( async () => { return dispatch( main_menu_show() ) }),
                hide:  ( async () => { return dispatch( main_menu_hide() ) }),
                toggle:( async () => { return dispatch( main_menu_toggle() ) }),
            },
            user_menu : {
                visible: user_menu_visible,
                reset: ( async () => { return dispatch( user_menu_reset() ) }),
                show:  ( async () => { return dispatch( user_menu_show() ) }),
                hide:  ( async () => { return dispatch( user_menu_hide() ) }),
                toggle:( async () => { return dispatch( user_menu_toggle() ) }),
            },

            toast : {
                types: ToastTypes,
                show:  ( async (msg, type=null, options={}) => { return dispatch( toast_show({msg,type,...options}) )}),
                dismissAll: ( async () => { return dispatch( toast_dismissAll() ) }),
            },

            THEMES: UI_THEMES,          // Just a reference to ALL themes. 
            THEME: theme_definition,    // quick handle to the def, for eaiser ref in code
            theme : {                   // full theme control object
                name : theme_name,      
                def: theme_definition,
                default: theme_default,
                set: ( async (pTheme) => { return dispatch( set_theme(pTheme) ) }),
                set_default: ( async (theme_default) => { return dispatch( set_theme(theme_default) ) }),
            },
            
            
        }),
        [main_menu_visible, user_menu_visible, theme_name, theme_definition, theme_default] // eslint-disable-line react-hooks/exhaustive-deps
    );

    /************************************************************************************************************************************************************* */
    /************************************************************************************************************************************************************* */

    return (
        <UIContext.Provider value={value}>

            {children}

            <div>
                <ToastContainer />

                <ScrollUp
                    startPosition={0}
                    showAtPosition={300}
                    position='right'
                    className='scroll-up'
                    style={{right: '40px'}}
                >
                    <Icon name="circleUp" size="3x" className="text-primary" />
                </ScrollUp>
            </div>

        </UIContext.Provider>
    );
};
export const useUI = () => {
    return useContext(UIContext);
};
