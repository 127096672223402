import React from "react";
import PropTypes from "prop-types";

import { Container, Navbar, Nav } from "react-bootstrap";

import { MainMenu, UserMenu } from "components/nav-menus";
import { getAllowedRoutes } from "config/PrivateRoutes.config";
//import useNetworkStatus from "hooks/useNetworkStatus";

import AppNavbarBrand from "./common/AppNavbarBrand";
import MainMenuButton from "./common/MainMenuButton";
import UserProfileButton from "./common/UserProfileButton";
import UserMessagesButton from "./common/UserMessagesButton";

import { useUI } from "hooks/useUI";

//import { useAuth } from "hooks/useAuth";

import { APP_CONSTANTS } from "config";

function AppHeaderNavbarPrivate(props) {
    
    // UI HOOKS
   const { main_menu, user_menu, THEME, theme} = useUI();

    let allowedRoutes = [];
    allowedRoutes = getAllowedRoutes();

   // const  //TODO: left off here. 

    return (
        <>
            <Navbar expand="lg" sticky="top" bg={THEME?.navbar?.bg} variant={THEME?.navbar?.variant} className={THEME?.navbar?.className} style={THEME?.navbar?.style}>
                <Container fluid>
                    <div className="d-flex flex-row justify-content-start">
                        <AppNavbarBrand to={APP_CONSTANTS.ROUTES.AUTH_HOME} />
                        <MainMenuButton onClick={() => { main_menu.show() } } />
                    </div>
                    <div className="d-flex flex-row justify-content-end">
                        <UserMessagesButton onClick={() => { user_menu.show() }} />
                        <UserProfileButton onClick={() => { user_menu.show() }} />
                    </div>
                </Container>
            </Navbar>

            {/* <div className="bg-body shadow-sm">
                <nav className="nav" aria-label="Secondary navigation">
                    <Nav.Link>Dashboard</Nav.Link>
                    <Nav.Link>
                        Friends{" "}
                        <span className="badge text-bg-light rounded-pill align-text-bottom">
                            27
                        </span>
                    </Nav.Link>
                    <Nav.Link>Explore</Nav.Link>
                    <Nav.Link>Suggestions</Nav.Link>
                    <Nav.Link>Link</Nav.Link>
                    <Nav.Link onClick={() => theme.set('light')}>Set Theme Light</Nav.Link>
                    <Nav.Link onClick={() => theme.set('dark')}>Set Theme Dark</Nav.Link>
                    <Nav.Link onClick={() => alert(JSON.stringify(THEME))}>Alert Theme State</Nav.Link>
                </nav>
            </div> */}

            <MainMenu
                routes={ allowedRoutes }
                show={ main_menu.visible }
                onHide={() => { main_menu.hide();}}
            />
            <UserMenu
                show={ user_menu.visible}
                onHide={() => { user_menu.hide();}}
            />
        </>
    );
}
AppHeaderNavbarPrivate.propTypes = {
    routes: PropTypes.arrayOf(
        PropTypes.shape({
            path: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
        })
    ).isRequired,
    className: PropTypes.string,
};
//shape() takes an object and validates the types inside the object.
AppHeaderNavbarPrivate.defaultProps = {
    basePath: "",
    className: "",
};
export default AppHeaderNavbarPrivate;
