import React from 'react';
import { Spinner } from 'react-bootstrap';
import { useAuth } from 'hooks/useAuth';

import { useOnMountRunOnce } from 'hooks/useOnMount';

function Logout(props) {
    const { logout } = useAuth();

    useOnMountRunOnce(() => {
        logout(); 
    });

	return (
		<Spinner animation="border" role="status">
            <span className="visually-hidden">SIGNING OUT...</span>
        </Spinner>
	);
}
export default Logout;

