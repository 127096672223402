import { Navigate, useOutlet } from "react-router-dom";
import { Container } from "react-bootstrap";
import { useAuth } from "hooks/useAuth";
import { useUI } from "hooks/useUI";
import {
    AppHeaderNavbarPrivate,
    AppFooterNavbarPrivate,
    InactivityTimer, Icon,
} from "components/common";

import { getAllowedRoutes } from "config/PrivateRoutes.config";
import { APP_CONSTANTS } from "config";
import useNetworkStatus from "hooks/useNetworkStatus";
import { useOnMountRunOnce } from "hooks/useOnMount";


export const PrivateLayout = () => {
    const outlet = useOutlet();

    const { current_user, verify_user } = useAuth();   /* eslint-disable-line no-unused-vars */
    const { isOnline } = useNetworkStatus();


    const { THEME } = useUI(); 

    // SECURITY CHECK AT PAGE LOAD ONLY - Verify the User Account
    // TODO:  need logic to stop this from running after initial login (first load) of this componant. It doesnt need to run when user just logged in, rather every mount after. 
    // TODO: was failing on mobile device. 
     useOnMountRunOnce(() => {
        console.log('DO A SECURITY CHECK HERE! CHECK TOKEN FOR VALIDITY')
        console.log('hopefully only running once on page load?')

        verify_user().then((verified) => {
            console.log("verify return " + JSON.stringify(verified)); 
        });
    })

    if (!current_user) {
        return <Navigate to={APP_CONSTANTS.ROUTES.LOGIN} />;
    }
    
    let allowedRoutes = [];
    allowedRoutes = getAllowedRoutes();

    return (
        <div className={THEME?.wrapper?.className}>
            <header>
                <AppHeaderNavbarPrivate
                    routes={allowedRoutes}
                    className="bg-white"
                />
                { (!isOnline) && 
                    <div className="alert alert-warning d-flex align-items-center justify-content-center" role="alert">
                        <Icon name="exclamationTriangle" className="text-danger me-1" size="2x" />
                        <div className="fw-bold text-center mx-4">NETWORK ERROR! NO INTERNET FOUND!</div>
                        <Icon name="exclamationTriangle" className="text-danger me-1" size="2x" />
                    </div>
                }
            </header>
            <section>
                <Container fluid>{outlet}</Container>
            </section>
            <footer>
                <AppFooterNavbarPrivate />
                <InactivityTimer debug={false} />
            </footer>
        </div>
    );
};
export default PrivateLayout;
