import React from 'react';
import PropTypes from 'prop-types';

const Button = (props) => {
    let output = "";
    
    let btnclass = "btn btn-" + props.variant +' '+ props.className;

    let caption = props.children!==undefined ? props.children : props.text;

    output = (
        <button type="submit" className={btnclass}  disabled={props.disabled}>
            {props.icon && props.icon}
            {caption}
        </button>
    )
	return output;
};
Button.propTypes = {
	text: PropTypes.string,
    variant: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
}
Button.defaultProps = {
    text: "Submit",
    variant: "primary",
    className: "",
    disabled: false,
};

export default Button;