import { showToastObj, dismissAllToast  } from "components/common/lib/messaging/Toast";
class ToastService {
    
    show(toastobject) {
        console.log('TOAST SERVICE - new toast:');
        console.log(toastobject);

        let response = showToastObj( toastobject )
        return {
            success:true,
            data: {
                response:response,
                toast: toastobject
            }   
        }
    }
    dismissAllToast() {
        console.log('TOAST SERVICE - dismiss all toast:');
        let response = dismissAllToast();
        return {
            success:true,
            data: {
                response:response,
            }   
        }
    }

   
}
export default new ToastService();