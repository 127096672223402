import React from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { Form as ReactBootstrapForm } from "react-bootstrap";

function Form(props) {
    // const [validated, setValidated] = useState(false);        // for react bootstrap form
    //  setValidated(false);

    // REACT-HOOK-FORM hook init
    const { handleSubmit } = useForm();

    const onSubmit = (data) => {
        if (props.onSubmit !== undefined) {
            props.onSubmit(data);
        }
    };

    return (
        <ReactBootstrapForm
            noValidate={props.noValidate}
            //   validated={validated}
            className={props.className}
            style={props.style}
            onSubmit={handleSubmit(onSubmit)}
        >
            {props.children}
        </ReactBootstrapForm>
    );
}
export default Form;
Form.propTypes = {
    name: PropTypes.string,
    onSubmit: PropTypes.func,
    className: PropTypes.string,
    style: PropTypes.object,
    noValidate: PropTypes.bool,

    validateFieldOnKeyUp: PropTypes.bool,
};
Form.defaultProps = {
    name: "form",
    noValidate: true,

    validateFieldOnKeyUp: false,
};
