/**
    CONVERTS OBJECTS WITH DAT TO FormData

    HOW TO USE:

            FormData.verify()

            Will chack a data object before passing into method that requires data in a FormDara browser object. 
            Pass in an standard object with form data values, or pass in an already made FormData.
            Will accept either object. 

            Utilizes(depends on) script in same directory called FormData.serializer.js

 */
import { serialize } from './FormData-serializer';


export function verify_FormData(dataObject, existingFormData=null) {
    //console.log("verify_FormData IN", dataObject);

    if (dataObject instanceof FormData) {
        //console.log("verify_FormData already FormData OUT", dataObject);
        return dataObject;
    } else {
        //console.log("verify_FormData OUT", dataObject);
        return serialize( dataObject )
        // TODO: Do we need toi use any options nin serialize?
    }
}

