import { showToast, ToastTypes } from "components/common/lib/messaging/Toast";


// for use  with hooks.
/**
 * Hook Exports... 
 * @returns 
 */
export const useErrorHandler = () => {
    return [ handleError ];
};

/**
 * handleError  -  main call for handling errors.  Optionally show them on the GUI in toast. 
 * @param {*} error 
 * @param {*} show 
 * @param {*} internalFlag 
 */
export const handleError = (error, show=true, internalFlag=false) => {  
    console.log('handleError called:', error);  

    // TODO: do something with error here like store it to the database to track errors. 
    // now show it.
    if (show) {
        showError(error, internalFlag);
    }

};




// for use with classes
const showError = (error, internalFlag=false) => {

    console.log('showError called:')
    console.log(error)

    let msg = '';
    if (internalFlag===true) msg += 'INTERNAL ERROR!  :: ';
    msg += deriveMessageFromError(error);

    showToast(msg, {
        type: ToastTypes.error,
        autoClose: false,
    });
};
const deriveMessageFromError = (err) => {
    /**
     *  Function takes in error object or string and retuns a message
     *
     */
    let message = "";

    if (typeof err === "object" && err !== null && "messsage" in err) {
        message = err.message;
    } else if (typeof err === "object" && err !== null && "toString" in err) {
        message = err.toString();
    } else if (typeof err === "string" && err !== null) {
        message = err;
    } else if (Array.isArray(err)) {
        message = err.join("<br />");
    }

    return message;
}
