import React from "react";
import { Route, Routes } from "react-router-dom";
import {
    Home,
    Register,
    Login,
    Logout,
    Forgot,
    ForgotSend,
    ForgotVerify,
    ForgotNewPass,
    ForgotSuccess,
} from "components/pages/public";
import PrivateRoutes from "./PrivateRoutes";
import { PrivateLayout, PublicLayout } from "components/layouts";
import { APP_CONSTANTS } from "config";

const AppRouter = () => {
    const AR = APP_CONSTANTS.ROUTES; // AR = App Routes shorthand
    return (
        <Routes>
            <Route element={<PublicLayout />}>
                <Route index element={<Home />} />
                <Route path="/" element={<Home />} />
                <Route path={AR.REGISTER} element={<Register />} />
                <Route path={AR.LOGIN} element={<Login />} />
                <Route path={AR.LOGOUT} element={<Logout />} />
                <Route path={AR.FORGOT} element={<Forgot />} />
                <Route path={AR.FORGOT + "/send"} element={<ForgotSend />} />
                <Route path={AR.FORGOT + "/verify"} element={<ForgotVerify />} />
                <Route path={AR.FORGOT + "/pass"} element={<ForgotNewPass />} />
                <Route path={AR.FORGOT + "/success"} element={<ForgotSuccess />} />
            </Route>
            <Route element={<PrivateLayout />}>
                <Route path="/*" element={<PrivateRoutes />} />
            </Route>
        </Routes>
    );
};
export default AppRouter;
