/**
 *      Theme Definition 
 *      
 *      'Yellow' Theme
 * 
 */
import {BASE_THEME_CONSTRUCT} from './_BASE_CONSTRUCT.theme';
export default Object.assign({}, BASE_THEME_CONSTRUCT, {
    colors: {
        // DEFINE THE COLORS HERE THAT ARE USED 
        primary: "yellow",
        secondary: "brown",
    },
    wrapper: {
        className:"app-theme-yellow",
    },
});
