import React from "react";
import { Button, Icon } from "components/common";

function MainMenuButton(props) {
    return (
        <Button
            variant="outline-light"
            className={props?.className}
            style={{ border: "none" }}
            onClick={() => {
                if (props?.onClick) props.onClick();
            }}
        >
            <Icon name="nav-hamburger-menu" size="xl" />
        </Button>
    );
}
export default MainMenuButton;
