import React from "react";
import PropTypes from 'prop-types';
import { useController } from "react-hook-form";
import { Form } from 'react-bootstrap';

function HiddenInput(props) {
    const { field: { onChange, onBlur, name, value, ref },
    } = useController({                    
        name: props.name,                                                      
        control: props.control,                                               
        defaultValue: props.value,
    });

    return (
        <Form.Control 
            id={name} name={name}
            onChange={onChange} onBlur={onBlur} value={value} ref={ref}    // <-- Binds the BS Control to react-hook-form Controller
            as={props.as}  type={props.type}
            className={props.className}
        />
    );
};
export default HiddenInput;

HiddenInput.propTypes = {
    as: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    className: PropTypes.string,
    value: PropTypes.string,
    
    control: PropTypes.object,   /* Pass in the react-hook-form controller to this. */
    ref: PropTypes.object,  // React element ref
}
HiddenInput.defaultProps = {
    as: 'input',   /*  input or textarea */
    type: 'text',
    value: "",
};