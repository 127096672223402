import React from "react";
import PropTypes from 'prop-types';
import { useController } from "react-hook-form";
import { Form } from 'react-bootstrap';

function Radio(props) {

    //const { field, fieldState, formState } = useController({  
    const { field: { onChange, onBlur, name, value, ref },
            fieldState: { invalid, isTouched },                                         /* eslint-disable-line */
    } = useController({                    // field:{ onChange, onBlur, name, value, ref },
        name: props.name,                                                       // fieldState: { invalid, isTouched, isDirty },
        control: props.control,                                                 // formState: { touchedFields, dirtyFields }
        defaultValue: props.value,
        // rules: {
        //     disabled:  props.rules?.disabled, 
        //     required:  props.rules?.required && props.feedback!=='' ? props.feedback : props.rules.required,
        //     validate: props.rules?.validate,
        // },
    });


    // DEBUGGING:
    // useEffect(() => {
    //     console.log("fieldState.invalid",fieldState.invalid);
    // }, []);

    const handleChange = (e) => {
        e.persist();
        console.log('radio chnaged: '+e.target.value);

        onChange(e);

        if (props.onChange!==undefined) {
            props.onChange(e);
        }
    }
    const handleBlur = (e) => {
        e.persist();

        onBlur(e);
        
        if (props.onBlur!==undefined) {
            props.onBlur(e);
        }
    }

    return (
        <>
        <Form.Check 
            type="radio"
            id={props.id}
            checked={props.checked}
            name={name}
            onChange={handleChange} onBlur={handleBlur} value={value} ref={ref}    // <-- Binds the BS Control to react-hook-form Controller
            label={props.label}
            inline={props.inline}
        />
        <div>value: {value.toString()}</div>
        <div>props.value: {props.value}</div>
        <div>props.checked: {props.checked.toString()}</div>
        </>
    );


};
export default Radio;

Radio.propTypes = {
    as: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    labelClass: PropTypes.string,
    className: PropTypes.string,
    controlClass: PropTypes.string,  
    value: PropTypes.string,
    helpText: PropTypes.string,
    helpTextClass: PropTypes.string,
    
    autoFocus: PropTypes.bool,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,

    inline: PropTypes.bool,

    control: PropTypes.object,   /* Pass in the react-hook-form controller to this. */
    ref: PropTypes.object,  // React element ref
    
    rules: PropTypes.object,
    
    validate: PropTypes.func,
    feedback: PropTypes.string,      // invalid feedback
    feedbackClass: PropTypes.string,
}
Radio.defaultProps = {
    as: 'input',   /*  input or textarea */
    label: null,
    required: false,
    autoFocus: false,
    value: "",
    readOnly: false,
    disabled: false,

    inline: false,
    controlClass:"",
    labelClass:"",
    helpTextClass: "",
    feedbackClass: "",
};