/**
 *      API_bridge.js
 *        
 *      This is our main API bridge the Service(s) Call(s). 
 *      - Tokens autmatically incluided in request
 *      
 *      HOW TO USE:
 *          AUTH_API    = use for calls to AUTH services like login, logout, find, etc...  (auth could technically beon different server or subdomain, or domain)
 *          API         = use for non secure 9no tokens) http calls to the main data server API
 *          API_SECURE  = use for Secure tokens, and http calls to the main data server API that require tokens for access
 *                
 * 
 *      IDEAS:
 *          https://stackoverflow.com/questions/62121287/how-to-setup-axios-interceptors-with-react-context-properly
 * 
 *          https://javascript.plainenglish.io/all-in-one-tutorial-on-how-to-create-the-authentication-part-of-your-react-app-2530e7435629
 */     

import axios from "axios";


/**
 *  AUTH_API
 *      - Htto API to App's AUTHentication REST services 
 * 
 *    method = "POST", or "GET"
 *    url
 *    formData object
 *    access_token
 * 
 * 
 */
export const AUTH_API = async (method, url, form_data=null, access_token='') => {
    /// create the instance with base url for auth loaded from App's env file, and use interceptors to add token in state in the requests
    const axiosInstance = axios.create({
        withCredentials: true,
        baseURL: process.env.REACT_APP_AUTH_API_URL,
        headers: {
            "Content-Type": "application/json",
        }
    });   
     // Request interceptor
     axiosInstance.interceptors.request.use((config) => {
        config.headers['Authorization'] = access_token ? ('Bearer '+access_token) : '';      // add token to request headers
        return config;
    });

    switch (method.toUpperCase()) {
        case "POST": return axiosInstance.post(url, form_data)   // return the axios post function
        case "GET": return axiosInstance.get(url, form_data)   // return the axios post function
        default: return axiosInstance.post(url, form_data)
    }    
}


//  Posts with Axios to our Data API, and optionally include token.
export const SECURE_API = async (method="POST", url, form_data, access_token=null) => {
    /// create the instance with base url for auth loaded from App's env file, and use interceptors to add token in state in the requests
    const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        headers: {
            "Content-Type": "application/json",
        }
    });   
    // REQUEST interceptor
    axiosInstance.interceptors.request.use((config) => {
        config.headers['Authorization'] = access_token ? ('Bearer '+access_token) : null;      // add token to request headers
        return config;
    });
    // RESPONSE interceptor
    axiosInstance.interceptors.response.use(
        function (response) {
            return response;
        },
        function (error) {
            if (error.response.status === 401 && access_token) {        // 401 = unauthorized
                //return ResetTokenAndReattemptRequest(error);
                console.log('DO SOMETING HERE TO GET A REFRESH TOKEN')
    
            } else {
                console.error(error);
            }
            return Promise.reject(error);
        }
    );

    switch (method) {
        case "POST": return axiosInstance.post(url, form_data)   // return the axios post function
        case "GET": return axiosInstance.get(url)   // return the axios post function
        default: return axiosInstance.post(url, form_data)
    }    
}




// Axios Http Object specific for NON_SECURE standard data API calls.
export const API = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        "Content-Type": "application/json",
    },
});
// Axios Http Object specific for SECURE standard data API calls.    (has jwt in header)
export const API_SECURE = axios.create({
    withCredentials: true,
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("access_token"),
    },
});
// Add a Axios Request Interceptor tot he secure API calls to include a token automatically. 
API_SECURE.interceptors.request.use(
    (config) => {
        // add the current stored JWT to the Header automatically with every call
        config.headers["Authorization"] = "Bearer " + localStorage.getItem("access_token");
        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);
API_SECURE.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        const access_token = localStorage.getItem("access_token");
        if (error.response.status === 401 && access_token) {        // 401 = unauthorized
            //return ResetTokenAndReattemptRequest(error);
            console.log('DO SOMETING HERE TO GET A REFRESH TOKEN')

        } else {
            console.error(error);
        }
        return Promise.reject(error);
    }
);





/*
let isAlreadyFetchingAccessToken = false;
let subscribers = [];

export async function ResetTokenAndReattemptRequest(error) {
    try {
        const { response: errorResponse } = error;
        const retryOriginalRequest = new Promise((resolve) => {
            addSubscriber((access_token) => {
                errorResponse.config.headers.Authorization = "Bearer " + access_token;
                resolve(axios(errorResponse.config));
            });
        });
        if (!isAlreadyFetchingAccessToken) {
            isAlreadyFetchingAccessToken = true;
            await api
                .post("/Auth/refresh", {
                    Token: localStorage.getItem("RefreshToken"),
                    LoginProvider: "Web",
                })
                .then(function (response) {
                    localStorage.setItem("Token", response.data.access_token);
                    localStorage.setItem("RefreshToken", response.data.refreshToken);
                    localStorage.setItem("ExpiresAt", response.data.expiresAt);
                })
                .catch(function (error) {
                    return Promise.reject(error);
                });
            isAlreadyFetchingAccessToken = false;
            onAccessTokenFetched(localStorage.getItem("Token"));
        }
        return retryOriginalRequest;
    } catch (err) {
        return Promise.reject(err);
    }
}
function onAccessTokenFetched(access_token) {
    subscribers.forEach((callback) => callback(access_token));
    subscribers = [];
}
function addSubscriber(callback) {
    subscribers.push(callback);
}
*/