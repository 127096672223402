import React, { useState, useEffect, memo } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useIdleTimer } from "react-idle-timer";
import moment from "moment";

import { Button, useModal, Modal } from "components/common";
import { APP_CONSTANTS } from "config";

import { useAuth } from 'hooks/useAuth';

const InactivityTimer = (props) => {
    const TIMEOUT_MINUTES = 10;
    const PROMPT_TIMEOUT_MINUTES = TIMEOUT_MINUTES-1;

    // ROUTING HOOKS
    const navigate = useNavigate();                         // Get navigate func from router hook. 

    // MODAL HOOKS
    const {modalVisible, toggleModal, showModal, hideModal} = useModal(false);

    // AUTH HOOKS
    const { logout } = useAuth();

    // HANDLERS
    const onPrompt = () => {
        // Fire a Modal Prompt
        console.log("prompt");
        showModal();
    };
    const onIdle = () => {
        // Close Modal Prompt
        // Do some idle action like log out your user
        // console.log('idle')
        navigate(APP_CONSTANTS.ROUTES.LOGOUT);
    };
    const onActive = (event) => {
        // Close Modal Prompt
        // Do some active action
        // hideModal()
        // console.log('active')
    };
    const onAction = (event) => {
        // Do something when a user triggers a watched event
        //  console.log('onAction')
    };
    
/*
const{ 
        start, reset, activate, pause, resume, isIdle, isPrompted, isLeader, getTabId, getRemainingTime, 
        getElapsedTime, getLastIdleTime, getLastActiveTime, getTotalIdleTime, getTotalActiveTime 
}=useIdleTimer;
*/
    const {
        reset,
        getRemainingTime
    } = useIdleTimer({
        onPrompt,
        onIdle,
        onActive,
        onAction,
        timeout: 1000 * 60 * TIMEOUT_MINUTES, 
        promptTimeout: 1000 * 60 * PROMPT_TIMEOUT_MINUTES,
        events: [
            "mousemove",
            "keydown",
            "wheel",
            "DOMMouseScroll",
            "mousewheel",
            "mousedown",
            "touchstart",
            "touchmove",
            "MSPointerDown",
            "MSPointerMove",
            "visibilitychange",
        ],
        immediateEvents: [],
        debounce: 0,
        throttle: 0,
        eventsThrottle: 200,
        element: document,
        startOnMount: true,
        startManually: false,
        stopOnIdle: false,
        crossTab: false,
        name: "idle-timer",
        syncTimers: 0,
        leaderElection: false,
    });

    const [timeVal, setTimeVal] = useState(getRemainingTime());
    useEffect(() => {
        setInterval(() => {
            const milliseconds = getRemainingTime();
            const timeleftString = moment.utc(milliseconds).format("mm:ss"); // convert milliseconds to mm:ss with moment
            setTimeVal(timeleftString);
        }, 1000);
    }, [getRemainingTime]);


    const handleStayLoggedIn = () => {
        reset();
        hideModal();
    }
    const handleLogoutNow = () => {
        logout();
    }

    return (
        <>
            {(props?.debug) &&
                <div className="p-5 text-center">
                    <p>Inactivity Timer is Present in this component</p>
                    <p>Countdown : {timeVal}</p>
                    <button onClick={toggleModal}>Show Inactivity Modal</button>
                </div>
            }


            <Modal
                size={"md"}
                title={"Inactivity Warning"}
                visible={modalVisible}
                closeButton={false}
                handleClose={() => hideModal()}
                showConfirm={false}
                confirmButtonText={"Stay signed in!"}
                handleConfirm={() => hideModal()}
            >
                <>
                    <div className="d-flex justify-content-center ">Time Remaining {timeVal}</div>
                    <div className="d-flex justify-content-center ">
                        <Button onClick={handleLogoutNow} variant="info">Log out now</Button>
                        <Button onClick={handleStayLoggedIn} variant="primary">Dismiss</Button>
                    </div>
                </>
            </Modal>
        </>
    );
};

InactivityTimer.propTypes = {
    seconds:      PropTypes.number,    
    debug:         PropTypes.bool,
   
};
InactivityTimer.defaultProps = {
    seconds:    null,
    debug:      false,

}
export default memo(InactivityTimer);
