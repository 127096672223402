/**                                                                                                                 by Paul Hardy 7/20/22
 *    Login.js  ------  Public Module route destination/page.
 *          -  Main Login Page
 */

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Container, Spinner, Alert } from "react-bootstrap";

import {
    Button,
    SubmitButton,
    Form,
    Input,
    HelmetHtmlTitle,
} from "components/common";

//import { useErrorHandler } from "hooks/useErrorHandler";

import { validatePhoneOrEmail } from "helpers/form-validation";
import { APP_CONSTANTS } from "config";
import { useAuth } from "hooks/useAuth";
import { useCookie, getCookie } from "hooks/useCookie";
import { useOnMountRunOnce } from "hooks/useOnMount";
import { useUI } from "hooks/useUI";

//import { getValue } from "@testing-library/user-event/dist/utils";

const Login = (props) => {
    /****************************************************************************************************
    // HOOK & VARIABLE DECLARATIONS
    **************************************************************************************************** */

    // ROUTING HOOKS
    const navigate = useNavigate(); // Get navigate func from router hook.

    // STATE HOOKS
    const [loading, setLoading] = useState(false); // For spinner.
    const [loginError, setLoginError] = useState(null); // For error message on form.

    // COOKIE HOOKS
    const [usernameCookie, setUsernameCookie] = useCookie(
        "login-username",
        getCookie("login-username")
    );

    // AUTH HOOKS
    const { current_user, login, forgot_reset } = useAuth();

    // UI HOOKS
    const { ui_reset_menus } = useUI();

    // FORM HANDLER HOOKS (react-hook-form)
    const {
        handleSubmit,
        control,
        trigger,
        formState: { isValid },
        setFocus, // eslint-disable-line no-unused-vars
        setValue,
        getValues,
        reset: resetForm,
    } = useForm({ mode: "all" });

    /****************************************************************************************************
    // ON MOUNT / USE EFFECT / WATCHERS :
    **************************************************************************************************** */

    // redirect at render finish.  If logged in.  Constantly watch
    React.useEffect(() => {
        if (current_user)
            navigate(APP_CONSTANTS.AUTH_BASE_PATH, { replace: true });
    }, [current_user, navigate]);

    //Reset the forgot pass process if any lingering.  Only run once at mount.
    useOnMountRunOnce(() => {
        forgot_reset();
    });

    // focus on mount, or prefill username
    useOnMountRunOnce(() => {
        if (
            usernameCookie !== undefined &&
            usernameCookie !== null &&
            usernameCookie !== ""
        ) {
            setValue("username", usernameCookie);
            setFocus("password");
        } else {
            setFocus("username");
        }
    });

    /****************************************************************************************************
    // RUNTIME FUNCS :
    **************************************************************************************************** */

    //  FUNC:  validateThisField - called
    const validateThisField = (e) => {
        // call useForm.trigger, with id passsd in, to validate just this field
        const id = e.target.id;
        trigger(id);
    };

    const onSubmit = async (oFormFields) => {
        setLoading(true);
        setLoginError(null);
        setUsernameCookie(getValues("username"));
        await login(oFormFields).then((error) => {
           // console.log("login return " + JSON.stringify(error));
           
            ui_reset_menus();

            if (error) {
                setLoginError(error); // errro onject has 'message'
                let u = getValues("username");
                resetForm();
                setValue("username", u);
                //setFocus('password');
            }
            setLoading(false);
        });
    };

    /****************************************************************************************************
    // RENDER : 
    **************************************************************************************************** */
    return (
        <>
            <HelmetHtmlTitle pageName="Login" />

            <Form
                className="form-signin text-center mt-5 ms-auto me-auto"
                onSubmit={handleSubmit(onSubmit)}
            >
                <h1 className="h4 mb-3 font-weight-normal">Please sign in.</h1>

                {loading && <Spinner animation="border" />}
                <div className="text-start">
                    <Input
                        name={"username"}
                        control={control}
                        label={"Email or Mobile#"}
                        floatingLabel={true}
                        autoComplete={"email phone mobile"}
                        helpText={null}
                        className={"mb-1 text-start"}
                        type={"text"}
                        size={"lg"}
                        autoFocus={false}
                        onKeyPress={validateThisField}
                        onKeyUp={validateThisField}
                        onBlur={validateThisField}
                        rules={{
                            required: true,
                            validate: (val) => {
                                return validatePhoneOrEmail(val);
                            },
                        }}
                        feedback={
                            "A valid email address or mobile # is required."
                        }
                        feedbackClass="text-start"
                    />

                    <Input
                        name={"password"}
                        control={control}
                        label={"Password"}
                        floatingLabel={true}
                        helpText={null}
                        className={"mb-1 text-left"}
                        type={"password"}
                        autoComplete={"password"}
                        size={"lg"}
                        autoFocus={false}
                        onKeyPress={validateThisField}
                        onKeyUp={validateThisField}
                        onBlur={validateThisField}
                        rules={{
                            required: true,
                            validate: (val) => {
                                return val.length >= 3;
                            },
                        }}
                        feedback={"Please enter a password"}
                        feedbackClass="text-start"
                    />
                </div>
                {loginError && (
                    <Alert
                        variant={"warning"}
                        onClose={() => setLoginError(null)}
                        dismissible
                    >
                        <div className="fst-italic small">{loginError}</div>
                    </Alert>
                )}

                <SubmitButton className="mt-3" disabled={!isValid} />

                <Container className="mt-3 d-flex justify-content-evenly">
                    {APP_CONSTANTS.REGISTER_ENABLED && (
                        <Button to="/register" variant="link">
                            Register
                        </Button>
                    )}
                    <Button to="/forgot" replace={true} variant="link">
                        Forgot?
                    </Button>
                </Container>
            </Form>
        </>
    );
};
export default Login;
