import { AUTH_API } from "./_API_bridge"
import { verify_FormData } from "helpers/FormData/FormData-verify";

class AuthService {
    fetchUserByToken = async (form_data) => {
        try {
            form_data = verify_FormData(form_data);                                     // call our helper that converts object data to FormData if needed.
            let response = await AUTH_API( "POST" , "user/" , form_data );
            return response;
        } catch (error) {
            return error;
        }
    };
    login = async (form_data) => {
        try {
            form_data = verify_FormData(form_data);                                     // call our helper that converts object data to FormData if needed.
            let response = await AUTH_API( "POST" , "login/" , form_data );
            return response;
        } catch (error) {
            return error;
        }
    };
    logout = async (form_data) => {
        try {
            form_data = verify_FormData(form_data);                                     // call our helper that converts object data to FormData if needed.
            let response = await AUTH_API( "POST" , "logout/" , form_data );
            return response;
        } catch (error) {
            return error;
        }
    };
    forgot_find = async (form_data) => {
        try {
            
            form_data = verify_FormData(form_data);                                     // call our helper that converts object data to FormData if needed.
            let response = await AUTH_API( "POST" , "forgot/find/" , form_data ); 
            //console.log("Auth Service ForgotFind response:", response);
            return response;
        } catch (error) {
            return error;
        }
    };
    forgot_send = async (form_data, access_token) => {
        try {
            form_data = verify_FormData(form_data);                                     // Call our helper that converts object data to FormData if needed.
            let response = await AUTH_API( "POST" , "forgot/send/" , form_data , access_token );  
            //console.log("Auth Service ForgotSend response:", response);
            return response;
        } catch (error) {
            return error;
        }
    };
    forgot_verify = async (form_data, access_token) => {
        try {
            form_data = verify_FormData(form_data);                                     // call our helper that converts object data to FormData if needed.
            let response = await AUTH_API( "POST" , "forgot/verify/" , form_data , access_token );  
            //console.log("Auth Service ForgotVerify response:", response);
            return response;
        } catch (error) {
            return error;
        }
    };
    forgot_pass = async (form_data, access_token) => {
        try {
            form_data = verify_FormData(form_data);                                     // call our helper that converts object data to FormData if needed.
            let response = await AUTH_API( "POST" , "forgot/pass/" , form_data , access_token );  
            //console.log("Auth Service ForgotPassChange response:", response);
            return response;
        } catch (error) {
            return error;
        }
    };
    register(username, email, password) {
        return AUTH_API.post("signup", {
            username,
            email,
            password,
        });
    }
}
export default new AuthService();
