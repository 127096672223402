import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { Container, Col, Offcanvas, Row } from "react-bootstrap";

import { Nav } from "react-bootstrap";

import { useAuth } from "hooks/useAuth";
import { APP_CONSTANTS } from "config";
import { Button,  } from "components/common";

import { useUI } from "hooks/useUI";

import NavLink from "./common/navlink";

const UserMenu = (props) => {
    /****************************************************************************************************
    // HOOK & VARIABLE DECLARATIONS
    **************************************************************************************************** */
    // ROUTING HOOKS
    const approutes = APP_CONSTANTS.ROUTES;

    const { pathname } = useLocation();

    // AUTH HOOKS
    const { logout, current_user } = useAuth();

    // UI HOOKS
    const { user_menu, theme, THEME, THEMES } = useUI();

    // STATE HOOKS
    const [show, setShow] = useState(props.show); // for  shwoing hideing the menu

    /****************************************************************************************************
    // ON MOUNT / USE EFFECT / WATCHERS :
    **************************************************************************************************** */
    // set the components state to the props passed in
    useEffect(() => {
        setShow(props.show);
    }, [props.show]);

    /****************************************************************************************************
    // RUNTIME FUNCS :
    **************************************************************************************************** */
    const handleClose = () => {
        setShow(false);

        if (props.onHide !== undefined) {
            props.onHide();
        }
    };
    const handle_logout_click = (goto_url) => {
        user_menu.hide(); // close this menu by using our UI controller hook
        logout();
    };
    const hide_this_menu = () => {
        user_menu.hide();
        console.log("hiding menu");
    };

    //const handleShow = () => setShow(true);
    /****************************************************************************************************
    // RENDER : 
    **************************************************************************************************** */
    return (
        <>
            <Offcanvas
                //className={"user-menu-offcanvas " + THEME?.offcanvas?.className}
                className={"user-menu-offcanvas"}
                //style={THEME?.offcanvas?.style}
                placement="end"
                show={show}
                onHide={handleClose}
            >
                <Offcanvas.Header
                    closeButton
                    // className={
                    //     "pb-0 mb-0 " + THEME?.offcanvas?.header?.className
                    // }
                    className="pb-0 mb-0 "
                    //style={THEME?.offcanvas?.header?.style}
                    //closeVariant={THEME?.offcanvas?.closeVariant}
                >
                    <Offcanvas.Title>MY ACCOUNT MENU</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className="pt-0 mt-0">
                    <div className="d-grid gap-2">
                        <Row className="">
                            <Col className="small text-center fst-italic">
                                SIGNED IN AS:
                            </Col>
                            <Col className="small text-center fst-italic">
                                { current_user.displayname }
                            </Col>
                        </Row>

                        <Button
                            variant="secondary"
                            onClick={handle_logout_click}
                        >
                            LOGOUT
                        </Button>

                        <hr />

                        <Nav
                            activeKey={pathname}
                            className={"d-flex flex-column"}
                        >
                            <NavLink
                                to={approutes.PROFILE}
                                icon="nav-my-profile"
                                theme="dark"
                                onClick={hide_this_menu}
                            >
                                My Profile
                            </NavLink>
                            <NavLink
                                to={approutes.PASSCHANGE}
                                icon="key"
                                theme="dark"
                                onClick={hide_this_menu}
                            >
                                My Password
                            </NavLink>
                        </Nav>

                        <Container>
                            <Row>
                                Theme: {theme.name}
                                <Nav className={"d-flex flex-column"}>

                                    {Object.entries(THEMES).map(([key, oTheme], index) => {
                                        return (
                                            <NavLink key={index} to={null} icon="circle" iconColor={oTheme?.def?.colors?.primary} onClick={() => theme.set(oTheme?.name)}>{oTheme.name}</NavLink>
                                        );
                                    })}

                                   
                                    {/* <NavLink to={null} icon="circle" iconColor="black" onClick={() => theme.set('default')}>Default</NavLink>
                                    <NavLink to={null} icon="circle" iconColor="black" onClick={() => theme.set('yellow')}>Dark</NavLink>
                                    <NavLink to={null} icon="circle" iconColor="gray" onClick={() => theme.set('light')}>Light</NavLink>
                                    <NavLink to={null} icon="circle" iconColor="yellow" onClick={() => theme.set('yellow')}>Yellow</NavLink> */}
                                </Nav>
                            </Row>
                        </Container>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
};
UserMenu.propTypes = {
    theme: PropTypes.string,
    show: PropTypes.bool,
    className: PropTypes.string,
};
UserMenu.defaultProps = {
    theme: "dark",
    show: false,
    className: "",
};
export default UserMenu;
