import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import AppRouter from "./routing/AppRouter";
import { AuthProvider } from "hooks/useAuth";
import { UiProvider } from "hooks/useUI";
import { history } from "helpers/history";
import { APP_CONSTANTS } from "./config";

function App() {
    return (
        <>
            <Helmet>
                <title>{APP_CONSTANTS.APP_NAME}</title>
            </Helmet>
            <BrowserRouter history={history}>
                <AuthProvider>
                    <UiProvider>
                        <AppRouter />
                    </UiProvider>
                </AuthProvider>
            </BrowserRouter>
        </>
    );
}
export default App;
