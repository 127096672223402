import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";

import { PersistGate } from "redux-persist/integration/react";   // redux-persist = for persisting redux state over page refreshes
import { persistStore } from "redux-persist";                    // redux-persist = for persisting redux state over page refreshes

import { HelmetProvider } from "react-helmet-async";

import App from "./App";
import  store from "./redux/store";
import "./App.scss"; // Importing Sass with Bootstrap CSS
import { createAlert } from "./helpers/messaging";

window.alert = createAlert;

let persistor = persistStore(store);    

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <React.StrictMode>
        <Provider store={store}>
           <PersistGate loading={null} persistor={persistor}>
                <HelmetProvider>
                    <App />
                </HelmetProvider>
           </PersistGate>
        </Provider>
    </React.StrictMode>
);
