/*
* These are the placeholder roles you can replace these with yours
*/
export const ROLES = {
	DEV:'DEV',
	SUPER_ADMIN: 'SUPER_ADMIN',
	ADMIN: 'ADMIN',
	MANAGER: 'MANAGER',
	EMPLOYEE: 'EMPLOYEE',
	CUSTOMER: 'CUSTOMER',
	GUEST: 'GUEST'
};