import React from 'react';
import { Card } from 'react-bootstrap';
import { Button } from 'components/common'
import { APP_CONSTANTS } from 'config';

function PublicHome() {
	return (
		<Card className="text-center">
			<Card.Header>PUBLIC MODULE</Card.Header>
			<Card.Body>
				<Card.Title>Home</Card.Title>
				<Card.Text>
					Welcome to {APP_CONSTANTS.APP_NAME}!  Members please login.
				</Card.Text>

				<Button to={APP_CONSTANTS.ROUTES.LOGIN} variant="link">Login</Button>
				
			</Card.Body>
		</Card>
	);
}
export default PublicHome;
