/**
 *      Theme Definition 
 *      
 *      'Light' Theme
 * 
 */
 import { BASE_THEME_CONSTRUCT } from "./_BASE_CONSTRUCT.theme";
 export default Object.assign({}, BASE_THEME_CONSTRUCT, {
     colors: {
         // DEFINE THE COLORS HERE THAT ARE USED
         primary: "#cccccc",
         secondary: "darkgray",
     },
     wrapper: {
         className: "app-theme-light",
     },
     background: {
         style: { backgroundColor: "#fdfdff" },
     },
     navbar: {
         className: "",
     },
     offcanvas: {
         className: "text-bg-light",
     },
 });