/**                                                                                                                 by Paul Hardy 7/20/22
 *    Forgot_5_Success.js  ------  Public Module route destination/page.
 *          -  STEP 5/5 of the Password Recovery Process.  Succes Notification
 *          -  User presented button to go to login and try new password.
 */

import React from "react";
import { useNavigate } from 'react-router-dom';
import { Container } from "react-bootstrap";
import {
    Button,
    HelmetHtmlTitle,
    CountdownTimer
} from "components/common";
import { APP_CONSTANTS } from 'config';

const ForgotSuccess = (props) => {
    /****************************************************************************************************
    // HOOK & VARIABLE DECLARATIONS
    **************************************************************************************************** */
    
    // ROUTING HOOKS
    const navigate = useNavigate();

    /****************************************************************************************************
    // RENDER : 
    **************************************************************************************************** */
    return (
        <>
            <HelmetHtmlTitle pageName="Forgot My Password" />

            <Container className="mt-3 d-flex flex-column justify-content-evenly">
                <h1 className="text-center">Success!</h1>
                <p className="text-center">Your password was successfully reset.</p>
                <Button to={APP_CONSTANTS.ROUTES.LOGIN} variant="link">
                    Go to Login
                </Button>
            </Container>
            <Container className="mt-3 d-flex justify-content-evenly">
                
            </Container>

            <CountdownTimer 
                seconds={30} 
                autostart={true}
                visible={false}
                onTimeout={ () => {
                    navigate(APP_CONSTANTS.ROUTES.LOGIN)
                }}
            />
        </>
    );
};
export default ForgotSuccess;