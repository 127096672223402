import React from 'react';
import PropTypes from 'prop-types';
import { LinkContainer } from 'react-router-bootstrap';
import { Button as ReactBootstrapButton } from 'react-bootstrap';

const Button = (props) => {
    let output = "";

    if (props.type && props.type==='submit') { 
        let btnclass = "btn btn-" + props.variant +' '+ props.className;
        output = (
            <button type="submit" className={btnclass}  href={props.href} target={props.target} disabled={props.disabled} style={props.style}>
                {props.icon && props.icon}
                {props.text}
                {props.children}
            </button>
        )
    } else if (props.href) {
        output = (
            <ReactBootstrapButton variant={props.variant} type={props.type} className={props.className} href={props.href} target={props.target} disabled={props.disabled} style={props.style}>
                {props.icon && props.icon}
                {props.text}
                {props.children}
            </ReactBootstrapButton>
        )
    } else if (props.to!==undefined && props.to!==null) {
        output = (
            
            <LinkContainer to={props.to}>
                <ReactBootstrapButton variant={props.variant} type={props.type} className={props.className} style={props.style}>
                    {props.icon && props.icon}
                    {props.text}
                    {props.children}
                </ReactBootstrapButton>
            </LinkContainer>
        )
    } else if (props.onClick) {
        output = (
            <ReactBootstrapButton variant={props.variant} type={props.type} className={props.className} onClick={props.onClick} style={props.style}>
                {props.icon && props.icon}
                {props.text}
                {props.children}
            </ReactBootstrapButton>
        )
    } 
    else {
        output = (
            <ReactBootstrapButton variant={"warning"} disabled={true}>ERR No Action Specified!</ReactBootstrapButton>
        )
    }
	return output;
};
Button.propTypes = {
	text: PropTypes.string,
    type: PropTypes.string,
    variant: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,

    disabled: PropTypes.bool,
}
Button.defaultProps = {
    text: null,
    variant: "primary",
    type:"button",
	target: "_self",
    className: "",
    style:null,
    onClick: null,
    to: null,
    href: null,

    disabled: false,
};

export default Button;