export const APP_CONSTANTS = {
    APP_NAME            :   "MyApp",
    APP_HEADER_LOGO     :   "MyApp",
    APP_COPYRIGHT       :   "\u00A9 2022",
    ROUTES              :   {
        LOGIN       : "/login",
        FORGOT      : "/forgot",
        LOGOUT      : "/logout",
        REGISTER    : "/register",
        PUBLIC_HOME : "/",
        AUTH_HOME   : "/my",
        PROFILE     : "/my/profile",
        PASSCHANGE  : "/my/pass",
    },

    AUTH_BASE_PATH      :   "/my",         // The route path to use behind all authentication protected areas of the app (password protected) 
    PUBLIC_HOME_PATH    :   "/home",       // Default "home" route for unauthenticated access. 

    REGISTER_ENABLED    :   true,           // Enable App's User Regiostration System?

    INACTIVITY_TIMER        : true,         // Inactivity Timer Enabled?
    INACTIVITY_TIMER_TIMEOUT: 10,          // minutes of inactivitity before auto logout.   Set 
    INACTIVITY_TIMER_PROMPT : true,         // will prompt before logout.

}