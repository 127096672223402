
import { UI_THEMES } from "config";

class ThemeService {

    getTheme = async (theme=null) => {
        try {

            let response = {
                data: {},
                error: null,
                success: false
            };

            let defaultTheme = UI_THEMES['default'];

            if (theme===null && defaultTheme!==undefined) {
                response.data = { ...UI_THEMES[defaultTheme] };
                response.success=true;
            }
            else if ( UI_THEMES[theme]!==undefined) {
                response.data = { ...UI_THEMES[theme] };
                response.success=true;
            } else {
                let error = 'Theme not found!!';

                response.data = null;
                response.error = error;
                response.success = false;
            }
            return response;
        } catch (err) {
            let error = 'Theme service Error!! Error = '+JSON.stringify(err)
            return {error};
        }
    };
}
export default new ThemeService();
