/**
 *      Theme Definition 
 *      
 *      Default Theme
 * 
 */
import { BASE_THEME_CONSTRUCT } from "./_BASE_CONSTRUCT.theme";
export default Object.assign({}, BASE_THEME_CONSTRUCT, {
    colors: {
        // DEFINE THE COLORS HERE THAT ARE USED
        primary: "lightblue",
        secondary: "gray",
    },
    wrapper: {
        className: "app-theme-default",
    },
    background: {
        style: { backgroundColor: "#fdfdff" },
    },
    navbar: {
        className: "",
    },
    offcanvas: {
        className: "text-bg-dark",
    },
});
 