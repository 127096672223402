import { useOutlet } from "react-router-dom";
import { Container } from "react-bootstrap";
import { useAuth } from "hooks/useAuth";
import {
    AppHeaderNavbarPublic,
    AppFooterNavbarPublic,
} from "components/common";

export const PublicLayout = () => {
    const { current_user } = useAuth();
    const outlet = useOutlet();

    if (current_user) {
        //return <Navigate to={APP_CONSTANTS.ROUTES.AUTH_HOME} replace />;
    }

    return (
        <>
            <AppHeaderNavbarPublic />
            <Container fluid>{outlet}</Container>
            <AppFooterNavbarPublic />
        </>
    );
};
export default PublicLayout;
