import React from "react";
import { Navbar } from "react-bootstrap"; 

import { APP_CONSTANTS } from 'config';


function AppFooterCopyright(props) {
    const version = "v" + process.env.REACT_APP_VERSION
    return ( 
        <div>
            <Navbar.Text className="app-footer-copyright">{ APP_CONSTANTS.APP_COPYRIGHT }</Navbar.Text>  
            <Navbar.Text className="mx-2">{" | "}</Navbar.Text> 
            <Navbar.Text className="app-footer-version">{ version }</Navbar.Text> 
        </div>
    );
}
export default AppFooterCopyright;