import React from "react";
import { Button, Icon } from "components/common";

import { useAuth } from "hooks/useAuth";

function UserMessagesButton(props) {
    const { isLoggedIn } = useAuth();
    return (
        <Button
            variant="outline-light"
            className="ms-2"
            disabled={!isLoggedIn}
            onClick={() => {
                if (props?.onClick) props.onClick();
            }}
        >
            <Icon name="envelope" size="1x" />
        </Button>
    );
}
export default UserMessagesButton;
