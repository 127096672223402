import React from "react";
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";

import { faCircleQuestion, faCircleUser, faCircleUp, faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faBars, faCheck, faCircle, faEye, faExclamation, faExclamationTriangle, faKey, faHouse, faLongArrowRight, faUserLarge } from "@fortawesome/free-solid-svg-icons";

// add imported icons to the fa libray which makes them available to FontAwesomeIcon
library.add(
        faBars,
        faCheck, 
        faCircle,
        faCircleQuestion,
        faCircleUp,
        faCircleUser,
        faEnvelope,
        faEye,
        faExclamation,
        faExclamationTriangle,
        faKey,
        faHouse,
        faLongArrowRight,
        faUserLarge
);


const Icon = (props) => {
    const {name, className, size, fixedWidth, spin, pulse, border, listItem, style} = props;

    const get_fa_icon = (name) => {
        return <FontAwesomeIcon icon={name} size={size} fixedWidth={fixedWidth} className={className} style={style} spin={spin} pulse={pulse} border={border} listItem={listItem} />
    }

    switch (name) {
        case 'bars'         : return get_fa_icon('fa-solid fa-bars'); 
        case 'circle'       : return get_fa_icon('fa-solid fa-circle'); 
        case 'circleQuestion': return get_fa_icon('fa-regular fa-circle-question'); 
        case 'circleUp'     : return get_fa_icon('fa-regular fa-circle-up'); 
        case 'circleUser'   : return get_fa_icon('fa-regular fa-circle-user'); 
        case 'check'        : return get_fa_icon('fa-solid fa-check'); 
        case 'exclamation'  : return get_fa_icon('fa-solid fa-exclamation'); 
        case 'exclamationTriangle'  : return get_fa_icon('fa-solid fa-triangle-exclamation'); 
        case 'eye'          : return get_fa_icon('fa-solid fa-eye'); 
        case 'envelope'     : return get_fa_icon('fa-regular fa-envelope'); 
        case 'home'         : return get_fa_icon('fa-solid fa-house'); 
        case 'key'          : return get_fa_icon('fa-solid fa-key'); 
        case 'userLarge'    : return get_fa_icon('fa-solid fa-user-large'); 

        case 'nav-circle-question' : return get_fa_icon('fa-regular fa-circle-question'); 
        case 'nav-hamburger-menu'  : return get_fa_icon('fa-solid fa-bars'); 
        case 'nav-my-home'         : return get_fa_icon('fa-solid fa-house'); 
        case 'nav-my-profile'      : return get_fa_icon('fa-solid fa-user-large'); 

        default             : return null; 
    }
};
export default Icon

Icon.propTypes = {
	name: PropTypes.string.isRequired,
    size: PropTypes.string,        // can be  controlled with the CSS font-size attribute, and FontAwesomeIcon's size prop
                                // one of:  ["2xs","xs","sm","lg","xl","2xl","1x","2x","3x","4x","5x","6x","7x","8x","9x","10x"]

    fixedWidth: PropTypes.bool,

    listItem: PropTypes.bool,   // Icons in  List?
    border: PropTypes.bool,     // Adds a border
    spin: PropTypes.bool,       // FontAwesoem Animation 
    pulse: PropTypes.bool,      // FontAwesoem Animation 

}
Icon.defaultProps = {
    name: null,
    size: "1x",
    fixedWidth: false,

    listItem: false,
    border: false,
    spin: false,
    pulse: false,

};