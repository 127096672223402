/*
    THEME  - Base Construct

    


*/
export const BASE_THEME_CONSTRUCT = {
   
        colors : {             // used for reference of the colors in any user GUI that inetracts with the theme chooser
            primary: "",
            secondary: "",
        },

        wrapper : {
            className:"app-theme-default",      // Can set theme by just this wrapper class only if desired.  See assets/styles/themes for scss based themes. 
        },


        // Override individual Classes, styles, and Bootstrapo props if desired versus, "Wrapper stylesheet" method above. 

        background : {
            className:"",
            style:{}
        },
        navbar : {
            className:"",
            style:{},
            bg: "",
            variant: "",
            navlink :{
                className:"",
            },
        },
        offcanvas : {          // Offcanvas are used for side-menus
            className:"",               // -- offcanvas class
            style:{},                   // -- offcanvas style
            header: {                   // OFFCANVAS HEADER
                closeVariant :"",           // -- offcanvas header - close button variant (bootstrap prop)
                className:"",               // -- offcanvas header - className
                style:{},                   // -- offcanvas header - style
                title: {                    // OFFCANVAS HEADER TITLE
                    className:"",           // -- offcanvas header title - className
                    style:{},
                },
            },
            body: {
                className:"",
                style:{},
                navlink :{
                    className:"",
                    style:{},
                },
            },
            
        },
}
