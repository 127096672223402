import React from 'react';


import { Card } from 'react-bootstrap';

import { LatinFiller } from 'components/dev-tools/LatinFiller'
import { Button } from 'components/common';
import { useUI } from 'hooks/useUI';
const MyHome = () => {
	//const { user: current_user } = useSelector((state) => state.auth);
	//if (!current_user) {return <Navigate to={APP_CONSTANTS.ROUTES.LOGIN} />}

	const { toast } = useUI();

	return (
		<Card className="text-center">
			<Card.Header>AUTHENTICATED PROTECTED MODULE</Card.Header>
			<Card.Body>
				<Card.Title>My Home Dashboard</Card.Title>

				<Button onClick={() => {toast.show('test',toast.types.info)}} >Test Toast</Button>

				<LatinFiller words={50} paragraphs={60} />
			</Card.Body>
		</Card>
	);
}
export default MyHome;
