

const BASE_CONSTRUCT = {
    colors: {
        primary: "",
        secondary: "",
    },
    wrapper: {
        className:"app-theme-default",
    },
    // background: {
    //     className:"",
    //     style:{}
    // },
    // navbar: {
    //     className:"",
    //     style:{},
    //     bg: "",
    //     variant: "",
    //     navlink :{
    //         className:"",
    //     },
    // },
    // offcanvas: {                    // Offcanvas are used for side-menus
    //     className:"",
    //     style:{},
    //     header: {
    //         closeVariant :"",       // Offcanvas Close "X" button
    //         className:"",
    //         style:{},
    //         title: {                // Title
    //             className:"",
    //             style:{},
    //         },
    //     },
    //     body: {
    //         className:"",
    //         style:{},
    //         navlink :{
    //             className:"",
    //             style:{},
    //         },
    //     },
        
    // },
}

// DEFAULT
const default_def = Object.assign({}, BASE_CONSTRUCT, {
    name:    "default",
    wrapper: {
        className:"app-theme-default",
    },
});

// DARK
const dark_def = Object.assign({}, BASE_CONSTRUCT, {
    name:    "dark",
    wrapper: {
        className:"app-theme-dark",
    },
});

//LIGHT
const light_def = Object.assign({}, BASE_CONSTRUCT, {
    name:    "light",
    wrapper: {
        className:"app-theme-light",
    }
});

// YELLOW
const yellow_def = Object.assign({}, BASE_CONSTRUCT, {
    name:    "yellow",
    wrapper: {
        className:"app-theme-light",
    }
});

export const UI_THEMES={
    default: { name:"default",   def : default_def},
    dark: { name:"dark",   def : dark_def},
    light: { name:"light",   def : light_def},
    yellow: { name:"yellow",   def : yellow_def},
}; 

export const get_default_theme_definition = () => {return {...default_def}}