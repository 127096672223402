import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { Offcanvas } from "react-bootstrap";

import { Nav } from "react-bootstrap";
import { unique_key } from "helpers/guid_generator";
import { useUI } from "hooks/useUI";
import NavLink from "./common/navlink"



const MainMenu = (props) => {
    /****************************************************************************************************
    // HOOK & VARIABLE DECLARATIONS
    **************************************************************************************************** */

    // ROUTING HOOKS
    const { pathname } = useLocation();

    // STATE HOOKS
     const [show, setShow] = useState(props.show); // for  shwoing hideing the menu

    // AUTH & AUTH-STATE HOOKS
    //const { access_token, access_expires, forgot, forgot_verify } = useAuth();

     // UI HOOKS  (only for nav click event)
     const { main_menu, THEME } = useUI();

    /****************************************************************************************************
    // ON MOUNT / USE EFFECT / WATCHERS :
    **************************************************************************************************** */
    // set the components state to the props passed in 
    useEffect(() => {
        setShow(props.show)
    },[props.show])

    /****************************************************************************************************
    // RUNTIME FUNCS :
    **************************************************************************************************** */
    const handleClose = () => {
        setShow(false)

        if (props.onHide!==undefined) {
            props.onHide()
        }
    };
   

    const hide_this_menu = () => {
        main_menu.hide()
        console.log('hiding menu')
    }

    /****************************************************************************************************
    // RENDER : 
    **************************************************************************************************** */
    return (
        <>
            <Offcanvas 
                className={ "main-menu-offcanvas " + THEME?.offcanvas?.className }
                style={THEME?.offcanvas?.style}
                placement="start"
                show={show}
                onHide={handleClose}
            >
                <Offcanvas.Header  
                    closeButton  
                    closeVariant={THEME?.offcanvas?.header?.closeVariant}
                    className={"pb-0 mb-0 " + THEME?.offcanvas?.header?.className }
                    style={THEME?.offcanvas?.header?.style}
                >
                    <Offcanvas.Title>MAIN MENU</Offcanvas.Title> 
                </Offcanvas.Header>
                <Offcanvas.Body 
                    className={"pt-0 mt-0 " + THEME?.offcanvas?.body?.className }
                    style={ THEME?.offcanvas?.body?.style }
                >
                    <div className="d-grid gap-2">
                         
                        <Nav activeKey={pathname} className={"d-flex flex-column"}>
                            {props.routes.map(({ path, title, icon }) => (
                                <NavLink 
                                    key={unique_key()} 
                                    to={path} icon={icon ? icon : ""} 
                                    className={THEME?.offcanvas?.body?.navlink?.className} 
                                    style={THEME?.offcanvas?.body?.navlink?.style} 
                                    onClick={hide_this_menu} 
                                >
                                    {title}
                                </NavLink>
                            ))}
                        </Nav>
                    
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
};
MainMenu.propTypes = {
    theme : PropTypes.string,
    show: PropTypes.bool,
    routes: PropTypes.arrayOf(
        PropTypes.shape({           //shape() takes an object and validates the types inside the object.
            path: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
        })
    ).isRequired,
    className: PropTypes.string,
};

MainMenu.defaultProps = {
    theme : 'dark',
    show: false,
    basePath: "",
    className: "",
};
export default MainMenu;
