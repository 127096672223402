import { createContext, useContext, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
//import { useLocalStorage } from "hooks/useLocalStorage";
//import { moment } from "moment";

import { APP_CONSTANTS } from "config";
import {
    verify_user as verify_user_slice,
    login as login_slice,
    logout as logout_slice,
    forgot_reset as forgot_reset_slice,
    forgot_find as forgot_find_slice,
    forgot_send as forgot_send_slice,
    forgot_verify as forgot_verify_slice,
    forgot_pass as forgot_pass_slice,
} from "redux/slices/auth-redux-slice";

import { handleError } from "./useErrorHandler";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();




    //const [user, setUser] = useLocalStorage("user", null); // eslint-disable-line no-unused-vars
   // const [access_token, setAccessToken] = useLocalStorage("access_token", null); // eslint-disable-line no-unused-vars
   // const [access_expires, setAccessExpires] = useLocalStorage("access_expires", null); // eslint-disable-line no-unused-vars

    // TODO: determine is current_user should be localstorage user or state.auth.user?
    //          will have to see how the user persists through app experience.

    // get vars to use from state.  we export them for use in the hook
    const {
        user,
        user: current_user, // rename to current_user
        access_token, access_expires, refresh_token,
        isLoggedIn,
        isFetching,
        errorMessage,
        forgot,
    } = useSelector((state) => state.auth);

    /************************************************************************************************************************************************************* */
    /************************************************************************************************************************************************************* */
   
   
    /************************************************************************************************************************************************************* */
    /************************************************************************************************************************************************************* */
    
    /**
     * 
     * @param {*} access_token 
     * @returns 
     */
    const verify_user = async () => {
        // dipatch verify and send results to the Store
        if (access_token===undefined || access_token===null || access_token==='') return false;
        return dispatch(verify_user_slice(access_token))
            .then((reducedAction) => {
                // console.log("reducedAction", reducedAction);
                const result = reducedAction.payload; // extract data fromt he returned reduced Action promise Object. The payload property holds the data the reducer used.
                if (result.error) {
                    console.log('User could not be verified! '+JSON.stringify(result.error));
                    return false; // return error string.  This func returns error strong or null.
                } else {
                    // successful verification.  State values were saved.  
                    return true; // null = success
                }
            })
            .catch((error) => {
                handleError(error, true, true); //handle & show our internal error toast.
            });
    };

    

    const login = async (dataObject) => {
        // dipatch login and send results to the Store. 
        // finish login routine.

        return dispatch(login_slice(dataObject))
            .then((reducedAction) => {
                // console.log("reducedAction", reducedAction);
                const result = reducedAction.payload; // extract data fromt he returned reduced Action promise Object. The payload property holds the data the reducer used.
                if (result.error) {
                    return result.error; // return error string.  This func returns error strong or null.
                } else {
                    // successful login.  State values were saved.  
                    return null; // null = success
                }
            })
            .catch((error) => {
                handleError(error, true, true); //handle & show our internal error toast.
            });
    };

    const logout = async () => {
        return dispatch(logout_slice({ uid: current_user.uid }))
            .then((reducedAction) => {
                // console.log("reducedAction", reducedAction);
                // clear all local storage for this domain.  or clear only keys by localStorage.removeItem('roles');
                localStorage.clear(); // clear all?
                // go back to login
                navigate(APP_CONSTANTS.ROUTES.LOGIN);
                return null;
            })
            .catch((error) => {
                handleError(error, true, true);
                return error;
            });
    };

    /************************************************************************************************************************************************************* */
    /************************************************************************************************************************************************************* */

    /** PASS RECOVERY :: STEP 0  - reset pass recovery process state to orginal state.
     *  forgot_reset  -
     */
    const forgot_reset = async () => {
        return dispatch(forgot_reset_slice())
            .then((reducedAction) => {
                const result = reducedAction.payload;               // extract data from the returned reduced Action promise Object. The payload property holds the data the reducer used.
                return result;                                      // successfully reset
            })
            .catch((error) => {
                handleError(error, true, true);
                return error;
            });
    };
    /** PASS RECOVERY :: STEP 1  - find the account
     *  forgot_find  -  Forgot Password function.  This is first process called when user forgets password. It searchs for a user in DB and returns a masked contact options for user to verify authentication.
     */
    const forgot_find = async (formdata) => {
        return dispatch(forgot_find_slice(formdata))
            .then((reducedAction) => {
                // console.log("reducedAction", reducedAction);
                const result = reducedAction.payload;               // extract data from the returned reduced Action promise Object. The payload property holds the data the reducer used.
                return result;                                      // successfully found account.  Results has: contact methods found.
            })
            .catch((error) => {
                handleError(error, true, true);
                return error;
            });
    };
    /** PASS RECOVERY :: STEP 2  - send a one-time-passcode to one of the methods found for the account
     *  forgot_send  -
     */
    const forgot_send = async (formdata) => {
        return dispatch(forgot_send_slice(formdata))
            .then((reducedAction) => {
                // console.log("reducedAction", reducedAction);
                const result = reducedAction.payload;
                return result; // successfully sent code.
            })
            .catch((error) => {
                handleError(error, true, true);
                return error;
            });
    };
    /** PASS RECOVERY :: STEP 3  - verify code sent to client with clients submitted code
     *  forgot_send  -
     */
    const forgot_verify = async (formdata) => {
        return dispatch(forgot_verify_slice(formdata))
            .then((reducedAction) => {
                // console.log("reducedAction", reducedAction);
                const result = reducedAction.payload;
                return result; // successfully sent code.
            })
            .catch((error) => {
                handleError(error, true, true);
                return error;
            });
    };
    /** PASS RECOVERY :: STEP 4  - submit new password to save
     *  forgot_pass  -
     */
    const forgot_pass = async (formdata) => {
        return dispatch(forgot_pass_slice(formdata))
            .then((reducedAction) => {
                const result = reducedAction.payload;       // extract data from the returned reduced Action promise Object. The payload property holds the data the reducer used.
                return result;                              // successfully sent new pass to server
            })
            .catch((error) => {
                handleError(error, true, true);
                return error;
            });
    };
    /************************************************************************************************************************************************************* */
    /************************************************************************************************************************************************************* */

    const value = useMemo(
        () => ({
            user,
            current_user,
            access_token,
            access_expires,
            refresh_token,
            isLoggedIn,
            isFetching,
            errorMessage,
            forgot,
            verify_user,
            login,
            logout,
            forgot_reset,
            forgot_find,
            forgot_send,
            forgot_verify,
            forgot_pass,
        }),
        [current_user, access_token, access_expires, refresh_token, isLoggedIn, forgot] // eslint-disable-line react-hooks/exhaustive-deps
    );

    return (
        <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};
