import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ToastService from "services/toast.service";
import ThemeService from "services/theme.service";

import {get_default_theme_definition} from "config/Theme.config";
// initial state of UI
const initialState = {
    theme : {
        error:  null,       // error string if occurs
        fetching: false,    // fetch/load flag
        name: "",           // current theme name
        def: get_default_theme_definition(), // current theme definition object
        default:"",         // App Instances's default theme name
    },
    main_menu: {
        visible: false,
        title: 'Main Menu',
        type:'offcanvas',    // todo: implement swicth to 'sidebar' type
        items: [],
    },
    user_menu: {
        visible: false,
        title: 'Main Menu',
        type:'offcanvas',    // todo: implement swicth to 'sidebar' type
        items: [],
    },

    toast: {       
        last:null,     
        shown:[],
    }
 
};

export const reset_all        = createAsyncThunk( "ui/reset/all",           async () => { return true; });
export const reset_menus      = createAsyncThunk( "ui/reset/menus",         async () => { return true; });

// main menu  (flyout offcanvas menu) visibility
export const main_menu_reset  = createAsyncThunk( "ui/menu/main/reset",     async () => { return true; });
export const main_menu_show   = createAsyncThunk( "ui/menu/main/show",      async () => { return true; });
export const main_menu_hide   = createAsyncThunk( "ui/menu/main/hide",      async () => { return true; });
export const main_menu_toggle = createAsyncThunk( "ui/menu/main/toggle",    async () => { return true; });

// user profile menu  (flyout offcanvas menu) visibility
export const user_menu_reset  = createAsyncThunk( "ui/menu/user/reset",      async () => { return true; });
export const user_menu_show   = createAsyncThunk( "ui/menu/user/show",      async () => { return true; });
export const user_menu_hide   = createAsyncThunk( "ui/menu/user/hide",      async () => { return true; });
export const user_menu_toggle = createAsyncThunk( "ui/menu/user/toggle",    async () => { return true; });

export const set_theme = createAsyncThunk( "ui/theme/set",  async (theme, thunkAPI) => {                                
    try {
        const response = await ThemeService.getTheme( theme );   
        if (response?.success) {
            return (response?.data);
        } else {
            return thunkAPI.rejectWithValue(response?.data);
        }
    } catch (error) {
        return thunkAPI.rejectWithValue(error);     
    }
});
 export const toast_dismissAll = createAsyncThunk( "ui/toast/dismiss/all",  async () => {                                
    try {
        const response = await ToastService.dismissAllToast();   
        if (response?.success) {
            return (response?.data);
        } 
    } catch (error) {
        return error    
    }
});
export const toast_show = createAsyncThunk( "ui/toast/show",  async (toastobject, thunkAPI) => {                                
    try {
        const response = await ToastService.show( toastobject );   
        if (response?.success) {
            return (response?.data);
        } else {
            return thunkAPI.rejectWithValue(response?.data);
        }
    } catch (error) {
        return thunkAPI.rejectWithValue(error);     
    }
});

const uiSlice = createSlice({
    name: "ui",
    initialState,
    reducers: {},
    extraReducers: {
        [reset_all.fulfilled]:         (state) => { state = { ...initialState };  },
        [reset_menus.fulfilled]:       (state) => { 
            state.main_menu = { ...initialState.main_menu };    // Main Menu - Reset
            state.user_menu = { ...initialState.user_menu };    // User Menu - Reset
        },

        // THEME
        [set_theme.pending]:       (state, action) => {
            state.theme.fetching = true;
        },
        [set_theme.fulfilled]:       (state, action) => {
            state.theme.fetching = false;
            state.theme.name = action?.payload?.name;
            state.theme.def = action?.payload?.def;
        },
        [set_theme.rejected]:       (state, action) => {
            state.theme = { ...initialState.theme }; 
            state.theme.error = action?.payload?.error;
        },

        // SIDEMAR - MAIN MENU
        [main_menu_reset.fulfilled]:  (state) => { state.main_menu = { ...initialState.main_menu }; },
        [main_menu_show.fulfilled]:   (state) => { state.main_menu.visible = true; },
        [main_menu_hide.fulfilled]:   (state) => { state.main_menu.visible = false; },
        [main_menu_toggle.fulfilled]: (state) => { state.main_menu.visible = !state.main_menu?.visible; },
        // SIDEMAR - USER MENU
        [user_menu_reset.fulfilled]:  (state) => { state.user_menu = { ...initialState.user_menu }; },
        [user_menu_show.fulfilled]:   (state) => { state.user_menu.visible = true;  },
        [user_menu_hide.fulfilled]:   (state) => { state.user_menu.visible = false;  },
        [user_menu_toggle.fulfilled]: (state) => { state.user_menu.visible = !state.user_menu?.visible; },


        [toast_show.fulfilled]:  (state, action) => { 
                const payload = action?.payload;
                state.toast.last = payload?.toast;
                state.toast.response = payload?.response;
                state.toast.shown.push(payload?.toast); 
        },
       
    },
});
export default uiSlice;
