import React from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar as ReactBootstrapButtonToolbar } from 'react-bootstrap';

const ButtonToolbar = (props) => {
    let output = "";
    output = (
        <ReactBootstrapButtonToolbar 
            role={props.role}
            bsPrefix={props.bsPrefix}
        >
            {props.children}
        </ReactBootstrapButtonToolbar>
    )
	return output;
};
ButtonToolbar.propTypes = {
    role: PropTypes.string,
    bsPrefix: PropTypes.string,
}
ButtonToolbar.defaultProps = {
    role: "toolbar",      // 'toolbar'
    bsPrefix: "btn-toolbar",  //btn-toolbar
};
export default ButtonToolbar;