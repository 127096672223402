import React from "react";
import { useAuth } from "hooks/useAuth";
import { Container, Row, Col, Card } from "react-bootstrap";

import userImagePlaceholder from 'assets/img/user.png';
import PageHeader from "components/layouts/parts/PageHeader";

import moment from "moment";

const DataField = ({label, data}) => {
    return(
        <div className="data-field">
            <div className="data-field-label">{label + ":"}</div>
            <div className="data-field-data ellipsis">{data}</div>
        </div>
    )
}

const Profile = () => {
    // AUTH HOOKS
    const { current_user, access_token, access_expires } = useAuth();

    return (

        //   <div className="container">
        //     <header className="jumbotron">
        //       <h3>
        //         <strong>{current_user?.username}</strong> Profile
        //       </h3>
        //     </header>
        //     <p>
        //       <strong>Token:</strong> {access_token.substring(0, 20)} ...{" "}
        //       {access_token.substr(access_token.length - 20)}
        //     </p>
        //     <p>
        //       <strong>Id:</strong> {current_user?.id}
        //     </p>
        //     <p>
        //       <strong>Email:</strong> {current_user?.email}
        //     </p>
        //     <strong>Authorities:</strong>
        //     <ul>
        //       {current_user?.roles &&
        //         current_user.roles.map((role, index) => <li key={index}>{role}</li>)}
        //     </ul>
        //   </div>

        <Container fluid>

            <PageHeader 
                title="My Profile"
                breadcrumbs={[
                    {caption:"Home",    to:"/"},
                    {caption:"My Home", to:"/my"},
                    {caption:"Setup",   to:"/setup"},
                ]}
            />
            


           
            <Row>
                <Col><DataField label="Name" data={current_user.displayname} /></Col>
            </Row>
            <Row>
                <Col><DataField label="Email" data={current_user.email} /></Col>
            </Row>
            <Row>
                <Col><DataField label="uid" data={current_user.uid} /></Col>
            </Row>
            <Row>
                <Col><DataField label="Token Expires" data={  moment(access_expires+10000000000081).utc().format('YYYY-MM-DD HH:mm:ss a')} /></Col>
                <Col><DataField label="Token Expires" data={ access_expires} /></Col>
            </Row>
            <Row>
                <Col><DataField label="token" data={access_token} /></Col>
            </Row>
            
        </Container>
    );
};
export default Profile;
