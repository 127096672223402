import { configureStore,combineReducers } from "@reduxjs/toolkit";
import authSlice from "./slices/auth-redux-slice";            // Authentication redux slice of actions, and reducers for auth. 
import messageSlice from "./slices/message-redux-slice";
import uiSlice from "./slices/ui-redux-slice";

// Needed for redux-persist integration.   See :  https://redux-toolkit.js.org/usage/usage-guide#use-with-redux-persist
import storage from 'redux-persist/lib/storage';       
import { persistReducer,
         FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER
       } from 'redux-persist';                          


const persistConfig = {
    key: 'root',
    storage,
    blacklist: [
        'ui',
    ],
}

const reducers_to_persist = combineReducers({
    auth:    authSlice.reducer,
    message: messageSlice.reducer,
    ui:      uiSlice.reducer,                        // TODO:Do we want this to persist?
});

const persistedReducer = persistReducer(persistConfig, reducers_to_persist);

const store= configureStore({
    reducer: persistedReducer,

    // See :  https://redux-toolkit.js.org/usage/usage-guide#use-with-redux-persist
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
    }),

    // do not forget this
    devTools: process.env.NODE_ENV !== 'production',
});


export default store;         // old way,


/*

OLD store setup before react-persist integrated. 

import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./slices/auth.slice";
import messageSlice from "./slices/message.slice";
const store= configureStore({
    reducer: {
        auth: authSlice.reducer,
        message: messageSlice.reducer,
    },
    // do not forget this
    devTools: process.env.NODE_ENV !== 'production',
    
});
export default store;
*/
