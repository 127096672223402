import React from 'react';
import PropTypes from 'prop-types';
import { LoremIpsum } from "./src/lorem-ipsum";

export const LatinFiller = ({
    paragraphs,
    words,
}) => {
    
    let output =[];

    var lipsum = new LoremIpsum();
	for (var p=0; p<paragraphs; p++) {
        output.push(lipsum.generate(words));
    }

    return (
		<div>
			{output.map((text, index) => <p key={index} className="mb-4 text-start">{text}</p>)}
		</div>
	);
};
LatinFiller.propTypes = {
    words: PropTypes.number,
    paragraphs: PropTypes.number,
    
};
LatinFiller.defaultProps = {
    words: 100,
    paragraphs: 15,
    
};
