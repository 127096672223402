/**                                                                                                                 by Paul Hardy 7/20/22
 *    Forgot_3_Verify.js  ------  Public Module route destination/page.
 *          -  STEP 3/5 of the Password Recovery Process.
 *          -  User presented input to enter One-Time-Passcode that was sent to them in previous step..  If Matches, on the STEP 4 (Forgot_4_NewPass.js)
 *          -  When verified, the App's store auth.forgot state is populated with data found, and access tokens that the next step.
 */

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Container, Spinner, Alert, Row } from "react-bootstrap";
import {
    OneTimePasscodeInput,
    Button,
    SubmitButton,
    Form,
    HelmetHtmlTitle,
    CountdownTimer,
} from "components/common";
import { useAuth } from "hooks/useAuth";
import { APP_CONSTANTS } from "config";

const ForgotVerify = (props) => {
    /****************************************************************************************************
    // HOOK & VARIABLE DECLARATIONS
    **************************************************************************************************** */

    // ROUTING HOOKS
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false); // for spinner
    const [verifyError, setVerifyError] = useState(null); // for error message on form
    const [timedOut, setTimedOut] = useState(false); //

    // AUTH & AUTH-STATE HOOKS
    const { access_token, access_expires, forgot, forgot_verify } = useAuth();

    // FORM HANDLER HOOKS (react-hook-form)
    const {
        handleSubmit,
        control,
        trigger,
        formState: { isValid },
        reset: resetForm,
    } = useForm({ mode: "all" });

    /****************************************************************************************************
    // ON MOUNT / USE EFFECT / WATCHERS :
    **************************************************************************************************** */

        //  future useEffect / onmount funcs here

    /****************************************************************************************************
    // RUNTIME FUNCS :
    **************************************************************************************************** */

    const onSubmit = async (formdata) => {
        formdata.token = access_token;

        // console.log('formdata',formdata);
        // return;

        setLoading(true);
        setVerifyError(null);

        await forgot_verify(formdata).then((result) => {
            if (result.success) {
                resetForm();
                navigate(APP_CONSTANTS.ROUTES.FORGOT + "/pass", {
                    replace: true,
                });
            } else if (result.error) {
                setVerifyError(result.error);
            }
            setLoading(false);
        });
    };

    const handleOtpOnChange = async (e) => {
        // call react-hook-form's useForm.trigger, with id passsd in, to validate just this field
        const validated = await trigger(e.target.id); // eslint-disable-line no-unused-vars

        // auto-submit when valid
        if (validated && !timedOut) {
            handleSubmit(onSubmit)(); // braces after function call is immediate execution.
        }
    };

    /****************************************************************************************************
    // RENDER : 
    **************************************************************************************************** */
    return (
        <>
            <HelmetHtmlTitle pageName="Forgot My Password - Verification" />

            <Form
                className="form-forgot text-center mt-5 ms-auto me-auto"
                style={{ maxWidth: "500px" }}
                onSubmit={handleSubmit(onSubmit)}
            >
                <h1 className="h4 mb-3 font-weight-normal">
                    PASSWORD RESET AUTHENTICATION
                </h1>
                <p className="text-start">
                    We just sent you a message. {forgot.send.message} Check your{" "}
                    {forgot.send.method} for the code and enter it below before
                    the expiration to continue.
                </p>
                <CountdownTimer
                    autostart
                    timestamp={access_expires}
                    label={"The authorization code expires in"}
                    labelExpired="Code Expired!"
                    format={"mm:ss"}
                    className={"mb-3"}
                    labelClass={"me-1"}
                    timeClass={"fw-bold"}
                    onTimeout={() => {
                        setTimedOut(true);
                        setVerifyError(
                            "The authorization code has expired. Try again."
                        );
                    }}
                />



                <div className="d-flex justify-content-center">
                    <OneTimePasscodeInput
                        length={6}
                        control={control}
                        name="client_otp"
                        onChange={handleOtpOnChange}
                        disabled={timedOut}
                    />
                </div>

                {verifyError && (
                    <Alert
                        variant={"warning"}
                        onClose={() => setVerifyError(null)}
                        dismissible
                    >
                        <div className="fst-italic small">{verifyError}</div>
                    </Alert>
                )}

                {loading && <Spinner animation="border" />}

                {(!timedOut) && (
                    <Container>
                        <Row className="mt-3 d-flex justify-content-evenly">

                            <Button
                                to={APP_CONSTANTS.ROUTES.LOGIN}
                                variant="secondary"
                            >
                                Cancel
                            </Button>
                            <Button
                                to={APP_CONSTANTS.ROUTES.FORGOT}
                                variant="primary"
                            >
                                Start Over
                            </Button>
                            
                        </Row>
                        <Row className="my-2  d-flex justify-content-center ">
                            {/** Another countdown here to auto-redirect to login if user doesnt respond. */}
                            <CountdownTimer
                                autostart
                                seconds={20}
                                label="Auto redirecting in"
                                labelExpired="Redirecting NOW"
                                format="mm:ss"
                                className="mb-3"
                                labelClass="small me-1"
                                timeClass="fw-bold"
                                onTimeout={() => {
                                    navigate(APP_CONSTANTS.ROUTES.LOGIN)
                                }}
                            />
                        </Row>
                    </Container>
                   
                )}

                {!timedOut && (
                    <>
                        {!loading && (
                            <SubmitButton
                                className="mt-3"
                                disabled={!isValid || timedOut}
                            >
                                Verify
                            </SubmitButton>
                        )}

                        <Container className="mt-3 d-flex justify-content-evenly">
                            <Button
                                to={APP_CONSTANTS.ROUTES.LOGIN}
                                variant="link"
                            >
                                cancel
                            </Button>
                            <Button
                                to={APP_CONSTANTS.ROUTES.FORGOT}
                                variant="link"
                            >
                                get new code
                            </Button>
                        </Container>
                    </>
                )}
            </Form>
        </>
    );
};
export default ForgotVerify;
