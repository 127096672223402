export function validatePhoneOrEmail(string) {
    /*  Checks for either or */
    const validEmail = validateEmail(string);
    const validPhone = validatePhone(string);
    if (validEmail===true || validPhone===true) {
        return true;
    } else {
        return false;
    }

}

export function validateEmail(email) 
{
     var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;  
     return re.test(email);
}

export function validatePhone(phone) 
{
    var re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/
    return re.test(phone.replace(/\s+/g, ''));

    /**
     *          
        valid formats:

        (123) 456-7890
        (123)456-7890
        123-456-7890
        123.456.7890
        1234567890
        +31636363634
        +3(123) 123-12-12
        +3(123)123-12-12
        +3(123)1231212
        +3(123) 12312123
        075-63546725
     * 
     * 
     */
}


export const default_password_criteria = {
    pc1: {
        ok: false,
        text: "Contains one number between 0-9.",
        pattern: /(?=.*[0-9])/,
    },
    pc2: {
        ok: false,
        text: "Contains one lowercase letter.",
        pattern: /(?=.*[a-z])/,
    },
    pc3: {
        ok: false,
        text: "Contains one uppercase letter.",
        pattern: /(?=.*[A-Z])/,
    },
    pc4: {
        ok: false,
        text: "Contains a symbol like: !@#$%^&*",
        pattern: /(?=.*[!@#$%^&*])/,
    },
    pc5: {
        ok: false,
        text: "Is at least 8 characters long.",
        pattern: /(?=.{8,})/,
    },
};
export function validatePassword(pass) 
{
    
    let crit = { ...default_password_criteria};

    for (let key in crit) {
        if (!(crit[key].pattern.test(pass))) return false;
    }
    return true;

    //  var re = /((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%]).{8,40})/;  
    //  return re.test(pass);

     /**
      * EXAMPLE REGEX EXPLAINED
      * 
        ((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%]).{8,40})

        (            # Start of group
        (?=.*\d)     # must contains one digit from 0-9
        (?=.*[a-z])  # must contains one lowercase characters
        (?=.*[A-Z])  # must contains one uppercase characters
        (?=.*[@#$%]) # must contains one special symbols in the list "@#$%"
        .            # match anything with previous condition checking
        {8,40}       # length at least 8 characters and maximum of 40 
        )    
    */
}

