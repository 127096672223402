import React from "react";

import { Container, Navbar, Nav } from "react-bootstrap";

import AppNavbarBrand from "./common/AppNavbarBrand";
//import useNetworkStatus from "hooks/useNetworkStatus";


function AppHeaderNavbarPublic(props) {
   // const { isOnline } = useNetworkStatus();
    return (
        <>
            <Navbar bg="dark" variant="dark" expand="lg" sticky="top" >
                <Container fluid>
                    <AppNavbarBrand />
                    <Nav className="text-light">
                        {
                            /* ONLINE/OFFLINE STATUS */  /* 
                            isOnline ? <div>ONLINE</div> : <div>OFFLINE</div>  */
                        }
                    </Nav>
                </Container>
                
            </Navbar>
        </>
    );
}
export default AppHeaderNavbarPublic;
