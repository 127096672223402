import React from 'react';
import { Card } from 'react-bootstrap';
import { Button } from 'components/common'
function DevHome() {
	return (
		<Card className="text-center">
			<Card.Header>AUTHENTICATED PROTECTED MODULE</Card.Header>
			<Card.Body>
				<Card.Title>Dev Home</Card.Title>
				<Card.Text>
					<Button to="/forgot" variant="link">Toast</Button>

				</Card.Text>
			</Card.Body>
		</Card>
	);
}
export default DevHome;
